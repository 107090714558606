const LOCAL_STORAGE_CLIENT_ID_KEY = 'innovest_client_id';

export const getClientEmitId = (event, clientId) => {
  return `${event}-${clientId}`;
};

export const generateRandomClientId = () => {
  // Generate a random timestamp component
  const timestamp = Date.now().toString(36);

  // Generate multiple random components
  const randomA = Math.random().toString(36).substring(2, 8);
  const randomB = Math.random().toString(36).substring(2, 8);
  return `innovest_${timestamp}_${randomA}${randomB}`;
};

export const getClientId = () => {
  // Check if client ID exists in localStorage
  let clientId = localStorage.getItem(LOCAL_STORAGE_CLIENT_ID_KEY);

  // If no existing client ID, generate and store a new one
  if (!clientId) {
    clientId = generateRandomClientId();
    localStorage.setItem(LOCAL_STORAGE_CLIENT_ID_KEY, clientId);
  }

  return clientId;
};
