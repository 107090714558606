export const getAudioUrl = (base64Audio) => {
  // Step 1: Convert Base64 PCM16 to binary string
  const binaryString = atob(base64Audio);

  // Step 2: Convert binary string to ArrayBuffer
  const pcmArrayBuffer = binaryStringToArrayBuffer(binaryString);

  // Step 3: Create WAV header with 24000 sampling rate
  const wavHeader = createWavHeader(pcmArrayBuffer.byteLength, 24000);

  // Step 4: Concatenate WAV header and PCM data
  const audioArrayBuffer = concatenateArrayBuffers(wavHeader, pcmArrayBuffer);

  // Step 5: Create a Blob from the ArrayBuffer and return URL
  //   const blob = new Blob([audioArrayBuffer], { type: 'audio/wav' });
  //   const audioUrl = URL.createObjectURL(blob);
  //   return audioUrl;
  const uint8Array = new Uint8Array(audioArrayBuffer);
  let binary = '';
  for (let i = 0; i < uint8Array.byteLength; i++) {
    binary += String.fromCharCode(uint8Array[i]);
  }
  base64Audio = btoa(binary);

  // Create audio URL from Base64
  const audioUrl = 'data:audio/wav;base64,' + base64Audio;
  return audioUrl;
};

export const convertBufferToBase64PCM16 = (buffer) => {
  const float32Array = new Float32Array(buffer);
  const int16Array = new Int16Array(float32Array.length);

  for (let i = 0; i < float32Array.length; i++) {
    // Convert float (-1.0 to 1.0) to int16 (-32768 to 32767)
    const sample = Math.max(-1, Math.min(1, float32Array[i]));
    int16Array[i] = sample < 0 ? sample * 32768 : sample * 32767;
  }

  // Create a Uint8Array from the Int16Array for base64 encoding
  const uint8Array = new Uint8Array(int16Array.buffer);

  // Convert to binary string
  let binary = '';
  for (let i = 0; i < uint8Array.byteLength; i++) {
    binary += String.fromCharCode(uint8Array[i]);
  }

  // Convert binary string to base64
  const base64Audio = btoa(binary);
  return base64Audio;
};

export const arrayBufferToBase64 = (buffer) => {
  const bytes = new Uint8Array(buffer);
  let binary = '';
  bytes.forEach((byte) => (binary += String.fromCharCode(byte)));
  return Buffer.from(binary, 'binary').toString('base64');
};

export const float32ToArrayBuffer = (float32Array) => {
  const buffer = new ArrayBuffer(float32Array.length * 4);
  const view = new DataView(buffer);
  float32Array.forEach((val, i) => view.setFloat32(i * 4, val, true));
  return buffer;
};

// Helper function to convert binary string to ArrayBuffer
const binaryStringToArrayBuffer = (binaryString) => {
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
};

// Helper function to create WAV header
const createWavHeader = (pcmDataLength, sampleRate) => {
  const numChannels = 1; // Mono
  const bitsPerSample = 16; // PCM16
  const byteRate = (sampleRate * numChannels * bitsPerSample) / 8;
  const blockAlign = (numChannels * bitsPerSample) / 8;
  const totalDataLength = 36 + pcmDataLength;

  const header = new ArrayBuffer(44);
  const view = new DataView(header);

  // RIFF chunk descriptor
  writeString(view, 0, 'RIFF');
  view.setUint32(4, totalDataLength, true);
  writeString(view, 8, 'WAVE');

  // fmt sub-chunk
  writeString(view, 12, 'fmt ');
  view.setUint32(16, 16, true); // fmt chunk size
  view.setUint16(20, 1, true); // Audio format (1 = PCM)
  view.setUint16(22, numChannels, true);
  view.setUint32(24, sampleRate, true);
  view.setUint32(28, byteRate, true);
  view.setUint16(32, blockAlign, true);
  view.setUint16(34, bitsPerSample, true);

  // data sub-chunk
  writeString(view, 36, 'data');
  view.setUint32(40, pcmDataLength, true);

  return header;
};

// Helper function to write a string to a DataView
const writeString = (view, offset, string) => {
  for (let i = 0; i < string.length; i++) {
    view.setUint8(offset + i, string.charCodeAt(i));
  }
};

// Helper function to concatenate ArrayBuffers
const concatenateArrayBuffers = (buffer1, buffer2) => {
  const result = new ArrayBuffer(buffer1.byteLength + buffer2.byteLength);
  const resultView = new Uint8Array(result);
  resultView.set(new Uint8Array(buffer1), 0);
  resultView.set(new Uint8Array(buffer2), buffer1.byteLength);
  return result;
};

export const floatToInt16 = (float32Array) => {
  const int16Array = new Int16Array(float32Array.length);
  for (let i = 0; i < float32Array.length; i++) {
    int16Array[i] = Math.max(-32768, Math.min(32767, float32Array[i] * 32768));
  }
  return int16Array;
};

export const floatTo16BitPCM = (float32Array) => {
  const buffer = new ArrayBuffer(float32Array.length * 2);
  const view = new DataView(buffer);
  let offset = 0;
  for (let i = 0; i < float32Array.length; i++, offset += 2) {
    const s = Math.max(-1, Math.min(1, float32Array[i]));
    view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
  }
  return buffer;
};

export const base64EncodeAudio = (float32Array) => {
  const arrayBuffer = floatTo16BitPCM(float32Array);
  let binary = '';
  const bytes = new Uint8Array(arrayBuffer);
  const chunkSize = 0x8000; // 32KB chunk size
  for (let i = 0; i < bytes.length; i += chunkSize) {
    const chunk = bytes.subarray(i, i + chunkSize);
    binary += String.fromCharCode.apply(null, chunk);
  }
  return btoa(binary);
};
