import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import App from "./App";
import { RealtimeAIProvider } from "./core/providers/realtime-ai.provider.js";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("chat-bot-ai"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RealtimeAIProvider>
        <App />
      </RealtimeAIProvider>
    </QueryClientProvider>
  </React.StrictMode>
);
