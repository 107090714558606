import React, { useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

import { BsSend, BsMic, BsMicMute } from 'react-icons/bs';
import { SlEmotsmile } from 'react-icons/sl';
import { sanitize } from '../../assets/functions';

import {
  NotificationContainer,
  ChatFooterContainer,
  IconsContainer,
  EmojiContainer,
  PoweredByImage,
  Notification,
  InputField,
  PoweredBy,
  ReplyBar,
  SendIcon,
  Icon,
} from './footer.styled';
import { useRealtimeAI } from '../../core/providers/realtime-ai.provider';
const ChatFooter = ({ settings, addNewMessage, notification }) => {
  const { pauseStreaming, continueStreaming, isPaused, isSocketConnect } = useRealtimeAI();

  const [userMessage, setUserMessage] = useState('');
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const onSubmit = () => {
    if (userMessage !== '') {
      addNewMessage(sanitize(userMessage));
      setUserMessage('');
    }
  };

  const onEnter = (event) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const onSelectEmoji = (data) => {
    setUserMessage(`${userMessage} ${data.native}`);
    setShowEmojiPicker(false);
  };

  let brandingUrl = settings?.brandingUrl
    ? settings?.brandingUrl?.startsWith('http')
      ? settings?.brandingUrl
      : `https://${settings?.brandingUrl}`
    : 'https://pivotpoint.biz/';

  return (
    <ChatFooterContainer>
      {notification !== '' && (
        <NotificationContainer>
          <Notification>{notification}</Notification>
        </NotificationContainer>
      )}
      {settings?.brandingImageUrl !== '' && (
        <PoweredBy>
          <a href={brandingUrl} target="_blank" rel="noopener noreferrer">
            <PoweredByImage
              src={
                settings?.brandingImageUrl ||
                'https://chatbuildxstorage.syd1.digitaloceanspaces.com/1713445895436-Powered%20by%20Pivot%20Point%20.png'
              }
              alt="powered by"
            />
          </a>
        </PoweredBy>
      )}
      <ReplyBar>
        <InputField
          id="inputMessageField"
          name="inputMessageField"
          type="text"
          placeholder="Type your message here..."
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyDown={onEnter}
          maxLength={512}
          autoFocus
        />

        <IconsContainer>
          {isSocketConnect && (
            <Icon onClick={() => (!isPaused ? continueStreaming() : pauseStreaming())}>
              {!isPaused ? <BsMic /> : <BsMicMute />}
            </Icon>
          )}
          {showEmojiPicker && (
            <EmojiContainer onMouseLeave={() => setShowEmojiPicker(false)}>
              <Picker data={data} onEmojiSelect={onSelectEmoji} />
            </EmojiContainer>
          )}
          <Icon onClick={() => setShowEmojiPicker((prev) => !prev)}>
            <SlEmotsmile />
          </Icon>
          <SendIcon onClick={() => onSubmit()} isEmpty={!userMessage}>
            <BsSend />
          </SendIcon>
        </IconsContainer>
      </ReplyBar>
    </ChatFooterContainer>
  );
};

export default ChatFooter;
