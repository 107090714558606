export const sortConversation = (data) => {
  const sortedData = data.data.sort((a, b) => {
    if (a.id > b.id) return 1;
    if (b.id > a.id) return -1;
    return 0;
  });

  return sortedData;
};

export const sanitize = (string) => {
  const reg = /<\/?[^>]+(>|$)/g;
  return string.replace(reg, '');
};

export const checkFormat = (text) => {
  if (!text || typeof text !== 'string') return 'plain';

  // Check for Markdown (common markdown elements)
  const markdownPatterns = [
    /^#{1,6} /m, // Headings (#, ##, ###)
    /\*\*[^*]+\*\*/, // Bold (**bold**)
    /__[^_]+__/, // Bold (__bold__)
    /\*[^*]+\*/, // Italics (*italic*)
    /_[^_]+_/, // Italics (_italic_)
    /~~[^~]+~~/, // Strikethrough (~~text~~)
    /\[[^\]]+\]\([^)]+\)/, // Links [text](url)
    /!\[[^\]]*\]\([^)]+\)/, // Images ![alt](url)
    /```[\s\S]+```/, // Code blocks (```code```)
    /`[^`]+`/, // Inline code (`code`)
    /^> /m, // Blockquotes (>)
    /^- |\* |\+ /m, // Unordered lists (-, *, +)
    /^\d+\. /m, // Ordered lists (1. ...)
  ];

  if (markdownPatterns.some((pattern) => pattern.test(text))) {
    return 'markdown';
  }

  return 'plain';
};

export const startConversationEvent = () => {
  if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
    window.gtag('event', 'chat_start', {
      event_category: 'chat bot',
      event_label: 'start bot conversation',
    });
  }
};

export const sentMessageEvent = () => {
  if (typeof window !== 'undefined' && typeof window.gtag === 'function') {
    window.gtag('event', 'chat_message', {
      event_category: 'chat bot',
      event_label: 'message sent',
    });
  }
};

export const getReferrerAndTrackingData = () => {
  const referrer = document.referrer || '';
  const currentUrl = window.location.href || '';
  const pageTitle = document.title || '';
  const params = new URLSearchParams(window.location.search);

  const clickIdKeys = [
    'gclid',
    'fbclid',
    'ttclid',
    'igclid',
    'msclkid',
    'twclid',
    'li_fat_id',
    'yclid',
  ];

  const sourceValue = {
    gclid: 'Google',
    fbclid: 'Facebook',
    ttclid: 'Tiktok',
    igclid: 'Instagram',
    msclkid: 'Microsoft',
    twclid: 'Twitter',
    li_fat_id: 'LinkedIn',
    yclid: 'Yandex',
  };

  let clickId = '';
  let source = '';

  for (const key of clickIdKeys) {
    if (params.has(key)) {
      clickId = params.get(key) || '';
      source = sourceValue[key] || '';
      break;
    }
  }

  const utm_source = params.get('utm_source') || '';
  const utm_medium = params.get('utm_medium') || '';
  const utm_campaign = params.get('utm_campaign') || '';

  return {
    referrer,
    currentUrl,
    pageTitle,
    source,
    clickId,
    utm_source,
    utm_medium,
    utm_campaign,
  };
};
