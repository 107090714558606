import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { io } from 'socket.io-client';
import { base64EncodeAudio, getAudioUrl } from '../utils/audio.util';
import { getClientEmitId, getClientId } from '../utils/realtime.util';

const RealtimeAIContext = createContext(null);

export const RealtimeAIProvider = ({ children }) => {
  const [isVoiceEnabled, setIsVoiceEnabled] = useState(false);
  const [conversationId, setConversationId] = useState('');
  const clientId = getClientId();
  const [messageHistories, setMessageHistories] = useState([]);
  const [isStreamConnect, setIsStreamConnect] = useState(false);
  const [isSocketConnect, setIsSocketConnect] = useState(false);
  const [isThinking, setIsThinking] = useState(false);
  const [transcription, setTranscription] = useState('');
  const [botResponse, setBotResponse] = useState('');
  const [isPaused, setIsPaused] = useState(false);
  const socketRef = useRef(null);
  const audioContextRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const processorRef = useRef(null);
  const audioQueueRef = useRef('');
  const chunksToPlayRef = useRef('');
  const isPlayingRef = useRef(false);
  const audioRef = useRef(null);
  const audioBuffersRef = useRef('');

  useEffect(() => {
    if (!isVoiceEnabled || !conversationId) return;

    if (!audioRef.current) {
      audioRef.current = new Audio();
      audioRef.current.onended = () => {
        playNextAudioChunk();
      };
      audioRef.current.onerror = (e) => {
        console.error('Audio playback error:', e);
        playNextAudioChunk(); // Try to play next chunk if there's an error
      };
    }

    socketRef.current = io(`${process.env.REACT_APP_BACKEND_URL}/tts-stt`, {
      path: '/socket.io/', // Ensure the correct path for Socket.io
      transports: ['websocket'], // Use WebSocket transport
      query: { clientId, conversationId }, // Pass the client ID as a query parameter
    });
    socketRef.current.on('connect', () => {
      setIsSocketConnect(true);
    });
    socketRef.current.on('disconnect', () => {
      setIsSocketConnect(false);
    });
    socketRef.current.on(getClientEmitId('thinking', clientId), ({ isThinking }) => {
      setIsThinking(isThinking);
    });
    socketRef.current.on(getClientEmitId('chatStart', clientId), () => {
      setBotResponse('');
      if (audioRef.current) {
        audioRef.current.src = '';
        audioRef.current.pause();
      }
      audioQueueRef.current = '';
      chunksToPlayRef.current = '';
      isPlayingRef.current = false;
    });
    socketRef.current.on(
      getClientEmitId('updatedMessageHistories', clientId),
      (messageHistories) => {
        setTranscription('');
        setBotResponse('');
        setMessageHistories(messageHistories);
      }
    );
    socketRef.current.on(getClientEmitId('transcription', clientId), ({ transcription }) => {
      if (!transcription) return;
      setTranscription(transcription);
    });
    socketRef.current.on(getClientEmitId('botResponseStart', clientId), () => {
      setBotResponse('');
    });
    socketRef.current.on(getClientEmitId('realtimeResponse', clientId), (data) => {
      switch (data.type) {
        case 'response.created':
          break;
        case 'response.audio_transcript.delta':
          setBotResponse((prev) => prev + data.delta);
          break;
        case 'response.audio.delta':
          audioQueueRef.current += data.delta;
          if (!isPlayingRef.current) {
            playNextAudioChunk();
          }
          break;
        case 'response.output_item.done':
          break;
        case 'response.done':
          break;
        default:
          console.warn(`Unhandled response type: ${data.type}`);
          break;
      }
    });

    return () => {
      socketRef.current?.disconnect();
      if (audioRef.current) {
        audioRef.current.pause();
      }
    };
    // eslint-disable-next-line
  }, [conversationId, isVoiceEnabled]);

  const playNextAudioChunk = () => {
    if (audioQueueRef.current.length === 0) {
      isPlayingRef.current = false;
      return;
    }

    isPlayingRef.current = true;
    const nextChunk = audioQueueRef.current;
    audioQueueRef.current = '';

    if (nextChunk && audioRef.current) {
      try {
        const audioUrl = getAudioUrl(nextChunk);
        audioRef.current.src = audioUrl;
        audioRef.current.oncanplaythrough = () => {
          if (audioRef.current) {
            audioRef.current.play().catch((err) => {
              console.error('Error playing audio:', err);
              playNextAudioChunk(); // Try next chunk if this one fails
            });
          }
        };
      } catch (error) {
        console.error('Error processing audio chunk:', error);
        playNextAudioChunk(); // Try next chunk if there's an error
      }
    }
  };

  const pauseStreaming = () => {
    setIsPaused((prev) => !prev);
    // Disconnect the processor to stop sending audio data
    if (processorRef.current && audioContextRef.current) {
      processorRef.current.disconnect(audioContextRef.current.destination);
    }
  };

  const continueStreaming = () => {
    if (!audioContextRef.current) {
      startStreaming();
    }
    audioBuffersRef.current = '';
    setIsPaused((prev) => !prev);
    // Reconnect the processor to resume sending audio data
    if (processorRef.current && audioContextRef.current) {
      processorRef.current.connect(audioContextRef.current.destination);
    }
  };

  const startStreaming = async () => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    mediaStreamRef.current = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });

    const source = audioContextRef.current.createMediaStreamSource(mediaStreamRef.current);
    processorRef.current = audioContextRef.current.createScriptProcessor(4096, 1, 1);

    processorRef.current.onaudioprocess = (event) => {
      if (isPaused) return; // Skip processing if paused

      const audioData = event.inputBuffer.getChannelData(0);
      // const detectSilence = (audioData, threshold = 0.05) => {
      //   // Check if the audio data contains values above the threshold
      //   for (let i = 0; i < audioData.length; i++) {
      //     if (Math.abs(audioData[i]) > threshold) {
      //       return false; // Not silence
      //     }
      //   }
      //   return true; // Is silence
      // };

      // const isSilence = detectSilence(audioData);

      // // Reset silence timer if sound is detected
      // if (!isSilence) {
      //   console.log('isSilence', isSilence);
      //   if (silenceTimerRef.current) {
      //     clearTimeout(silenceTimerRef.current);
      //     silenceTimerRef.current = null;
      //   }
      //   const base64Audio = base64EncodeAudio(audioData);
      //   socketRef.current?.emit('sendAudio', {
      //     audio: base64Audio,
      //     clientId,
      //     rateHertz: audioContextRef.current?.sampleRate || 44100,
      //   });
      // } else if (!silenceTimerRef.current) {
      //   // Start silence timer if not already running
      //   silenceTimerRef.current = setTimeout(() => {
      //     //console.log('3 seconds of silence detected');
      //     silenceTimerRef.current = null;
      //   }, 3000);
      // }
      // if (silenceTimerRef.current) {
      //   clearTimeout(silenceTimerRef.current);
      //   silenceTimerRef.current = null;
      // }
      const base64Audio = base64EncodeAudio(audioData);
      socketRef.current?.emit('sendAudio', {
        audio: base64Audio,
        clientId,
        rateHertz: audioContextRef.current?.sampleRate || 44100,
      });
    };

    source.connect(processorRef.current);
    processorRef.current.connect(audioContextRef.current.destination);

    socketRef.current?.emit('startRealtime', {
      clientId: clientId,
      sample_rate: audioContextRef.current.sampleRate,
    });
    setIsStreamConnect(true);
  };

  const value = {
    setConversationId,
    startStreaming,
    pauseStreaming,
    continueStreaming,
    setIsVoiceEnabled,
    isStreamConnect,
    isSocketConnect,
    isThinking,
    transcription,
    botResponse,
    isPaused,
    messageHistories,
  };

  return <RealtimeAIContext.Provider value={value}>{children}</RealtimeAIContext.Provider>;
};

export const useRealtimeAI = () => {
  const context = useContext(RealtimeAIContext);
  if (!context) {
    throw new Error('useRealtimeAI must be used within a RealtimeAIProvider');
  }
  return context;
};
